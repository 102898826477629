import React from 'react';
import { useMsal } from '@azure/msal-react';
import { StringParam, useQueryParam } from 'use-query-params';

import { apiLogout, isBrowser } from "../services/auth";

const Signout: React.FC<any> = () => {
    const { instance } = useMsal();

    const [origin, setOrigin] = useQueryParam("origin", StringParam);
    console.log('GATSBY_SIGNUP_URL', process.env.GATSBY_LOGOUT_REDIRECT_NEXT_APP_URL)
    const nextAppUrl = slashize(process.env.GATSBY_LOGOUT_REDIRECT_NEXT_APP_URL || '/');

    if (!origin && isBrowser()) {
        const originText = '?origin=florabank';
        window.location.href = nextAppUrl + 'signout' + originText;
    }
    else {
        apiLogout();
        isBrowser() && localStorage.clear();
        instance.logoutRedirect();
    }

    return <></>
};

const slashize = (url: string) => {
    return url + (url?.substr(url.length - 1, 1) != '/' ? '/' : '');
};

export default Signout;